// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// JQuery UI
@import '~jquery-ui/themes/base/all.css';

// Datatables BS5
@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";


// Select2
@import '~select2';

// SweetAlert
@import "~sweetalert2/dist/sweetalert2.css";

// Material Design Icons
@import "~@mdi/font/scss/materialdesignicons";

// Animate.css
@import "~animate.css/source/animate.css";

// Slick
@import "./library/slick.css";
@import "./library/slick-theme.css";

